import React from 'react'
import Img from 'gatsby-image'

const CroppedImage = (props) => (
  <Img
    sizes={props.sizes} 
    style={{
        height: "40vh",
        width: "100%",
        margin: "2.5em 0"
    }}
    imageStyle={{
        height: "100%",
        width: "100%"
    }}
  />  
)

export default CroppedImage
