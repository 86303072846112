import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import BannerLanding from '../components/BannerLanding'
import CroppedImage from '../components/CroppedImage';
import PageHelmet from '../components/PageHelmet';
import Layout from '../components/layout';
import { graphql } from 'gatsby'


const Landing = (props) => (
  <Layout>
    <PageHelmet title="About" />

    <div id="main" className="alt">
      <div className="inner">
        <section id="about">
          <header className="major">
            <h1>The Team</h1>
          </header>
          <CroppedImage sizes={props.data.childPuzzleMat.childImageSharp.fluid} />
          <p>Littlebirds Speech, Language and Literacy is an experienced and friendly paediatric speech, language and literacy service. We are passionate about helping your child develop their speech and language skills to become a successful communicator. We are committed to maintaining high professional standards and engage in ongoing professional development.</p>
          <p>Nicole has over 15 years experience working with toddlers, preschoolers and school aged children who find communicating challenging. Nicole has worked in many non-profit and government agencies, in New Zealand, Ireland and Australia.</p>
          <p>Specialty areas include Phonology and Articulation (speech sound difficulties) and Developmental Apraxia of Speech. Aural Habilitation (hearing impairment), Fluency (stuttering), Developmental Language Delays (understanding and/or using language), communication difficulties related to cognitive impairments, supporting Augmentative and Alternative Communication (AAC) use and Early-Literacy development.</p>
          <p>We also offer a comprehensive service for children who are finding reading and spelling challenging in Years 1-6.</p>
        </section>
        <section id="location">
          <header className="major">
            <h1>Location</h1>
          </header>
          <CroppedImage sizes={props.data.redCar.childImageSharp.fluid} />
          <p>Littlebirds Speech, Language and Literacy is located in the northern Porirua suburb of Camborne. If you are unable to attend sessions we may be able to offer online sessions. Please note parents/caregivers are expected to be present for these appointments as we believe parents play a crucial role in the success of the intervention.</p>
        </section>
      </div>
    </div>
  </Layout>
)

export default Landing

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                ... on ImageSharp {
                    resize(width: 125, height: 125, rotate: 180) {
                    src
                    }
                }
                }
            }
        },
        childPuzzleMat: file(
            relativePath: { regex: "/childPuzzleMat.jpg/" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 1240
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          },
        redCar: file(
            relativePath: { regex: "/redCar.jpg/" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 1240
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          },
    }
`;
